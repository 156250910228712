import React from 'react'
import { Link } from 'react-router-dom'

const PrimaryButton =({path, text, vote}) => (
 
    <Link to={path} onClick={vote} className="text-center hover:animate-pulse bg-primary-100 font-medium hover:bg-blue-700 px-10  md:py-4 py-2 text-primary-200">
    {text}
    
</Link>

);

export default PrimaryButton;
