import React from 'react'
import DropDown from '../../DropDown'
import Formfield from '../../Formfield'





const Kategorie = ({ handle, data, title, decription }) => {

    return (
        <>
            <div className="flex flex-col md:py-8">
                <div className='w-full p-8 bg-secondary-200 items-end '>
                    <h1>{title}</h1>
                    <div className="bg-primary-100 h-1 w-32 mb-4"></div>
                    {decription}
                </div>
                <Formfield onChange={handle} data={data.a} id="a" label="Öffentliche Angebote" placeholder={data.afrage} />
                <Formfield onChange={handle} data={data.b} id="b" label="Private Angebote" placeholder={data.bfrage} />
                <Formfield onChange={handle} data={data.c} id="c" label="Benötigte Angebote" placeholder={data.cfrage} />
                <DropDown onChange={handle} data={data.d} id="d" label="Wie wichtig?" placeholder={data.dfrage} />
            </div>



        </>
    )
}

export default Kategorie
