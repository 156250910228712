import React from 'react'
import { useState } from 'react'
import NavigatorForm from './Navigator/NavigatorForm'
import { useNavigate } from 'react-router-dom'



const Navigator = ({ handle, data }) => {
    const [state, setstate] = useState(0)
    const [rotation, setRotation] = useState(0)
    let navigate = useNavigate();

    const weiter = () => {
        console.log(rotation);
       
        console.log("enter weiter");
        if (state === 5) {
            navigate("/Person");
        };

        setRotation(prevRotation => prevRotation+60);
        setstate(prevCount => prevCount +1);
        document.getElementById('kategorie_scroll').scrollTop = 0;
        console.log(rotation);
    }
   
    const zuruck = () => {
        if (state === 0) {
            navigate("/Projektbeschreibung");
        };
        setstate(prevCount => prevCount - 1);
        setRotation(rotation - 60);
        window.scrollTo(0, 0);
    }


    return (
        <>
            <div className="navi md:basis-1/4 z-0">
                <img className="navigator_mobile " id="navigator_image" src="img/Success_Navigator_mobile.svg" alt="" />
                <img className="navigator_desktop" id="navigator_image_desktop" src="img/Success_Navigator_desktop.svg" alt="" />
            </div>
            <div className="md:basis-3/4 flex flex-col justify-between p-4 md:p-12 z-10 overflow-auto md:overflow-hidden md:mt-0" id='navi_form'>
                <NavigatorForm handle={handle} data={data} weiter={weiter} zuruck={zuruck} state={state}></NavigatorForm>
                <div className="flex-col md:flex-row md:flex-none flex md:justify-end md:gap-6 gap-2 pt-1 md:pt-12">
                    <button onClick={zuruck} className="bg-primary-200 text-primary-100 font-medium hover:bg-blue-700 px-10 md:py-4 py-2 border border-primary-100 ">zurück</button>
                    <button onClick={weiter} className="hover:animate-pulse bg-primary-100 font-medium hover:bg-blue-700 px-10  md:py-4 py-2 text-primary-200">weiter</button>
                </div>
            </div>


        </>
    )
}

export default Navigator
